import React from "react";

function About() {
  return (
    <div>
      <h2 className="c12 display-3">About us</h2>
    </div>
  );
}

export default About;
