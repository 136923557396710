import React, { Component } from 'react';
import axios from 'axios';
import './NewPost.css';
import {Redirect} from 'react-router-dom';

class NewPost extends Component {
    state = {
        title: '',
        content: '',
        author: 'Vikas',
        submitted: false
    }

    postDataHandler = () => {
      const data = {
        title: this.state.title,
        body: this.state.content,
        author: this.state.author
      };

      axios.post('https://jsonplaceholder.typicode.com/posts', data)
      .then(res => {
        console.log(res);
        this.setState({submitted:true});
      });
    }

    render () {
      let redirect = null;
      if (this.state.submitted) {
        redirect= <Redirect to="/blog" />
      }
        return (
            <div className="NewPost">
              {redirect}
                <h1>Add a Post</h1>
                <label>Title</label>
                <input type="text" value={this.state.title} onChange={(event) => this.setState({title: event.target.value})} />
                <label>Content</label>
                <textarea rows="4" value={this.state.content} onChange={(event) => this.setState({content: event.target.value})} />
                <label>Author</label>
                <select value={this.state.author} onChange={(event) => this.setState({author: event.target.value})}>
                    <option value="Vikas1">Vikas1</option>
                    <option value="Vikas2">Vikas2</option>
                </select>
                <button onClick={this.postDataHandler}>Add Post</button>
            </div>
        );
    }
}

export default NewPost;
