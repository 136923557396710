import React from "react";

function Services() {
  return (
    <div>
      <h2 className="c12 display-3">Services</h2>
    </div>
  );
}

export default Services;
